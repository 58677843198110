<template>
  <section class="select-position-skeleton">
    <skeleton class="select-position-skeleton__selector" />
  </section>
</template>

<script>
import Skeleton from './Skeleton'

export default {
  name: 'WorkflowSelectPositionSkeleton',

  components: {
    Skeleton
  }
}
</script>

<style lang="scss">
.select-position-skeleton {
  &__selector {
    height: $base*10;
    margin-top: $base*4;
    width: $base*100;
  }
}
</style>
