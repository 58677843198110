<template>
  <section v-if="defaultSelectedPositionId" class="workflow">
    <workflow-header class="workflow__workflow-header">
      <workflow-select-position-skeleton v-if="!loadedPositions" />
      <section v-else class="workflow__workflow-header-links">
        <router-link
          class="workflow__back-link"
          :to="{ name: 'Positions' }"
        >
          <ev-icon
            class="workflow__back-link-arrow"
            name="angle-left"
            size="small"
            color="gray60"
            bg-color="gray5"
          />
        </router-link>
        <workflow-select
          class="workflow__position-select"
          data-test="position-select"
          :positions="formattedPositionSelect"
          :title="selectedPosition.title"
          :private-position="selectedPosition.privatePosition"
          @position-changed="handlePositionChange"
        />
        <span
          @click="redirectToEdit"
          data-test="position-edit"
        >
          <ev-icon
            class="workflow__position-edit"
            name="edit-alt"
            size="small"
            color="gray60"
            bg-color="transparent"
          />
        </span>
        <section class="workflow__subtitle">
          <a
            v-if="publicPosition"
            href="javascript:void(0);"
            class="workflow__position-link"
            :data-ga-id="gaEventId"
            data-test="position-link"
            @click="handleOpenJobPosting"
          >
            <span
              class="status-bullet"
              :class="{ 'status-bullet--visible': isActive }"
            />
            <span class="position-link">{{ positionLinkTitle }}</span>
          </a>
        </section>
      </section>
      <section class="workflow__buttons-group">
        <app-select
          class="workflow__buttons-group__candidates-filter"
          data-test="candidates-filter"
          name="candidates-filter"
          :value="filter"
          :options="filters"
          @input="selectFilter"
        />
        <ev-button
          variant="secondary"
          color="blue"
          class="workflow__add-button"
          data-ga-id="clicked_add_candidate"
          data-test="add-candidate-button"
          @click="openAddCandidate()"
        >
          <span class="workflow__add-tooltip">
            {{ $t(`${langPath}.addCandidate`) }}
          </span>
        </ev-button>
      </section>
    </workflow-header>
    <router-view />
  </section>
</template>

<script>
import WorkflowHeader from './components/WorkflowHeader'
import WorkflowSelect from './components/WorkflowSelect'
import WorkflowSelectPositionSkeleton from '@/components/Skeletons/WorkflowSelectPositionSkeleton'
import EvIcon from '@revelotech/everestV2/EvIcon'
import EvButton from '@revelotech/everestV2/EvButton'

import { createNamespacedHelpers } from 'vuex'
import { CURRENT_USER, FILTERS, POSITIONS, COMPANY } from '@/store/namespaces'

import newsStorage from '@/helpers/news-storage.js'

const filtersHelper = createNamespacedHelpers(FILTERS)
const currentUserHelper = createNamespacedHelpers(CURRENT_USER)
const companyHelper = createNamespacedHelpers(COMPANY)
const positionsHelper = createNamespacedHelpers(POSITIONS)

export default {
  name: 'Workflow',
  components: {
    WorkflowHeader,
    WorkflowSelect,
    WorkflowSelectPositionSkeleton,
    EvButton,
    EvIcon
  },
  data () {
    return {
      langPath: __langpath,
      loadedPositions: false,
      newsOpened: true
    }
  },
  computed: {
    ...filtersHelper.mapState(['filter', 'employerFilter']),
    ...positionsHelper.mapState(['positions']),
    ...companyHelper.mapState(['company']),
    ...currentUserHelper.mapState(['currentUser']),
    gaEventId () {
      if (!this.hasJobPosting) return 'clicked_on_workflow_publish_position'

      return 'clicked_on_workflow_edit_job_posting'
    },
    positionLinkTitle () {
      if (this.isActive) return this.$t(`${this.langPath}.positionPublished`)
      else return this.$t(`${this.langPath}.publishPosition`)
    },
    selectedPosition () {
      const filteredPosition = this
        .positions
        .flat()
        .find(item =>
          parseInt(item.id) === parseInt(this.$route.params.positionId)
        )
      return filteredPosition || {}
    },
    hasJobPosting () {
      return !!this.selectedPosition?.sitePositionLink
    },
    groupPositionsByOwner () {
      return this.positions.reduce((h, obj) => {
        h[obj.owner] = (h[obj.owner] || []).concat(obj)

        return h
      }, {})
    },
    formattedPositionSelect () {
      const positionsGrouped = this.groupPositionsByOwner
      let formattedSelect = []

      formattedSelect = formattedSelect.concat({
        id: 'my-positions-label',
        title: this.$t(`${this.langPath}.positionSelect.myPositions`),
        labelOption: true
      })

      if (positionsGrouped.true) {
        formattedSelect = formattedSelect.concat(positionsGrouped.true)
      }

      if (positionsGrouped.false) {
        formattedSelect = formattedSelect.concat({
          id: 'other-positions-label',
          title: this.$t(`${this.langPath}.positionSelect.otherPositions`),
          labelOption: true
        })

        formattedSelect = formattedSelect.concat(positionsGrouped.false)
      }

      return formattedSelect
    },
    defaultSelectedPositionId () {
      if (this.groupPositionsByOwner?.true) {
        return this.groupPositionsByOwner.true[0].id
      } else {
        return this.positions[0]?.id
      }
    },
    isActive () {
      return this.selectedPosition?.jobPosting?.active
    },
    publicPosition () {
      return !this.selectedPosition?.privatePosition && this.currentUser?.company?.planId
    },
    filters () {
      const filters = [
        {
          value: 'all_candidates',
          label: this.$t(`${this.langPath}.filters.allCandidates`)
        },
        {
          value: 'my_candidates',
          label: this.$t(`${this.langPath}.filters.myCandidates`)
        }
      ]
      if (this.employerFilter?.id) {
        filters.push({
          value: 'candidates_from',
          label: this.$t(
            `${this.langPath}.filters.candidatesFrom`,
            { employerName: this.employerFilter.name }
          )
        })
      }
      return filters
    }
  },
  async created () {
    this.$smartlook('track', 'accessed_ats')
    await this.getShortPositions()
    this.loadedPositions = true
    this.selectFirstPosition()
    this.getFilters()
    this.newsOpened = newsStorage.isNewsRead('news-read', this.currentUser.id)
  },
  methods: {
    ...filtersHelper.mapActions(['getFilters', 'setFilter', 'setDefaultFilter']),
    ...positionsHelper.mapActions(['getShortPositions']),
    handleOpenJobPosting () {
      newsStorage.setNewsRead('news-read', this.currentUser.id)
      this.$router.push({ name: 'JobPosting', params: { positionId: this.selectedPosition.id } })
    },
    handlePositionChange () {
      this.newsOpened = newsStorage.isNewsRead('news-read', this.currentUser.id)
      this.setDefaultFilter()
    },
    openAddCandidate () {
      this.$smartlook('track', 'add_candidate_clicked')
      this.$router.push({
        name: 'CandidateAdd',
        params: {
          positionId: this.$route.params.positionId
        }
      })
    },
    selectFilter (filter) {
      const events = {
        all_candidates: 'clicked_all_candidates',
        my_candidates: 'clicked_my_candidates',
        candidates_from: 'clicked_candidates_from'
      }
      this.$smartlook('track', events[filter])
      this.$gtmTrackEvent(events[filter])
      this.setFilter(filter)
    },
    selectFirstPosition () {
      if (!this.$route.params.positionId && this.$route.name === 'Workflow') {
        if (!this.defaultSelectedPositionId) return

        this.$router.push({
          name: 'WorkflowBoard',
          params: {
            positionId: this.defaultSelectedPositionId
          }
        })
      }
    },
    async redirectToEdit () {
      this.$router.push({
        name: 'PositionsEdit',
        params: { positionId: this.selectedPosition.externalId },
        query: { redirect: encodeURIComponent(window.location.href) }
      })
    }
  }
}
</script>

<style lang='scss'>
.workflow {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $app-header-height $base*10 0;
  width: 100%;

  &__back-link {
    @extend %body-text1;

    align-items: center;
    display: flex;
    padding: 16px 0;

    &-arrow {
      border-radius: 50%;
    }
  }

  &__position-edit {
    cursor: pointer;
    margin: 0 $base*6;
  }

  &__position-link {
    @extend %body-text2;

    background-color: $gray5;
    border-radius: $base*8;
    color: $gray100;
    height: $base*8;
    line-height: $base*8;
    padding: 0 $base*4;
    text-decoration: underline;
  }

  &__subtitle {
    align-items: center;
    display: flex;
    width: $base*46;
  }

  &__buttons-group {
    display: inline-flex;

    &__candidates-filter {
      margin-right: 2*$base;
    }
  }

  &__workflow-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: $base * 13;
  }

  &__workflow-header-links {
    align-items: center;
    display: flex;
  }

  .position-link {
    display: inline-block;
    margin-left: $base*2;
  }

  .status-bullet {
    background: $gray60;
    border-radius: 50%;
    display: inline-block;
    height: 2*$base;
    width: 2*$base;

    &--visible {
      background: $success;
    }
  }

  .new-label {
    display: inline-block;
    margin-left: $base;
  }
}
</style>
