<template>
  <header class="workflow-header">
    <slot />
  </header>
</template>

<script>
export default {
  name: 'WorkflowHeader'
}
</script>

<style lang="scss">
.workflow-header {
  background: $white;
  padding: $base*8 0;
  transition: 0.3s;
  z-index: 15;
}
</style>
