<template>
  <nav
    class="workflow-select"
    :class="{
      'workflow-select--unselectable': !positions.length,
      'workflow-select--opened': isOpened
    }"
    tabindex="-1"
    @blur="setOpen(false)"
  >
    <ev-icon
      v-if="privatePosition"
      class="workflow-select__lock-icon"
      color="gray100"
      data-test="workflow-select__lock-icon"
      name="padlock"
    />
    <p
      data-test="workflow-select__title"
      class="workflow-select__title"
      @click="setOpen(!isOpened)"
    >
      {{ title }}
    </p>
    <ul
      v-if="positions.length > 0"
      class="workflow-select__selector"
    >
      <li
        v-for="position of positions"
        :key="position.id"
        data-test="workflow-select__option"
        class="workflow-select__option"
        :class="{
          'label-option': position.labelOption
        }"
        @click="handleRedirect(position.id)"
      >
        <icon-base
          v-if="position.title === title"
          class="workflow-select__option-icon"
          :icon-name="'check'"
        />
        {{ position.title }}
      </li>
    </ul>
  </nav>
</template>

<script>
import EvIcon from '@revelotech/everestV2/EvIcon'
import IconBase from '@/components/IconBase'

export default {
  name: 'WorkflowSelect',
  components: {
    EvIcon,
    IconBase
  },
  props: {
    positions: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    privatePosition: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isOpened: false
    }
  },
  methods: {
    handleRedirect (id) {
      const selectedPosition = this.positions.find(position => position.id === id)
      if (selectedPosition.owner) {
        this.$smartlook('track', 'clicked_my_positions')
        this.$gtmTrackEvent('clicked_my_positions')
      } else {
        this.$smartlook('track', 'clicked_other_positions')
        this.$gtmTrackEvent('clicked_other_positions')
      }

      this.setOpen(false)
      if (id === this.$route.params.positionId) return

      this.$router.push({
        name: 'WorkflowBoard',
        params: {
          positionId: id
        }
      })

      this.$emit('position-changed', id)
    },
    setOpen (opt) {
      this.isOpened = opt
    }
  }
}
</script>

<style lang='scss'>
.workflow-select {
  $self: &;

  position: relative;

  &__title {
    @extend %h4;

    align-items: center;
    color: $gray100;
    cursor: pointer;
    display: inline-flex;
    margin-left: $base*3;
    position: relative;
    transition: 0.4s;

    &::after {
      border: 2px solid $gray100;
      border-bottom: 0;
      border-left: 0;
      border-radius: 0 $base/2 0 0;
      content: ' ';
      display: block;
      height: 8px;
      margin-left: $base*3;
      margin-right: $base*3;
      transform: rotate(135deg) translate(-50%, 0%);
      transition: 0.2s ease-in-out;
      width: 8px;
    }

    &:hover { opacity: 0.9; }
  }

  &__lock-icon {
    height: $base*6;
    margin-left: $base*5;
    width: $base*6;
  }

  &__selector {
    background: $white;
    border-radius: $base;
    box-shadow: $shadow-16;
    display: inline-block;
    left: 0;
    max-height: 50vh;
    min-width: 300px;
    overflow-y: auto;
    padding-bottom: $base*4;
    position: absolute;
    top: $base*11;
    transform: scaleY(0);
    transform-origin: top center;
    transition: 0.15s;
  }

  &__option {
    @extend %base-typography;

    cursor: pointer;
    padding: $base*2;
    padding-left: $base*6;
    position: relative;

    &-icon {
      left: $base;
      position: absolute;
    }

    &:last-child { margin-bottom: 0; }

    &:hover {
      background: $gray5;
    }

    &.label-option {
      @extend %caption;

      cursor: default;
      margin-top: 2 * $base;
      padding-left: 4 * $base;
      pointer-events: none;
      text-transform: uppercase;
    }
  }

  &--opened {
    .workflow-select__title {
      &::after {
        margin-left: $base*4;
        margin-right: $base*3;
        transform: rotate(-45deg) translate(0, 0%);
      }
    }

    .workflow-select__selector {
      transform: scaleY(1);
    }
  }

  &--unselectable &__title {
    cursor: default;

    &::after {
      display: none;
    }

    &:hover { opacity: 1; }
  }
}
</style>
